import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useAtomValue } from "jotai";

import {
  GET_PARTNER_DETAIL_RES,
  UPDATE_PARTNER_REQ,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/partnerManagement";
import ADMIN_PARTNER_MANAGEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_PARTNER_MANAGEMENT_QUERY";
import { AdminBidTransportMode } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  PartnerBusinessArea,
  SelectedCountry,
} from "@sellernote/_shared/src/types/forwarding/partnerManagement";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/auth";

import CountryInCharge from "pages/partner-management/components/CountryInCharge";
import PartnerType from "pages/partner-management/components/PartnerType";
import TransportMode from "pages/partner-management/components/TransportMode";
import NO_LABEL from "pages/partner-management/styles";
import BOX_STYLE from "../styles";

import PartnerIcon from "./PartnerIcon";

export default function PartnerInfo({
  data,
  refetchDetail,
  partnerInfo,
  setPartnerInfo,
}: {
  data: GET_PARTNER_DETAIL_RES;
  refetchDetail: () => void;
  partnerInfo: Partial<UPDATE_PARTNER_REQ>;
  setPartnerInfo: Dispatch<SetStateAction<Partial<UPDATE_PARTNER_REQ>>>;
}) {
  const {
    name,
    businessArea,
    countries,
    language,
    transportModes,
    invoiceIssueFlag,
    isFinancial,
    address,
  } = data;

  const { id }: { id: string } = useParams();

  const { handleSnackbarOpen } = useSnackbar();

  const { authority } = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const isFinanceTeamUser = authority === "finance";

  const handleSuccess = () => {
    handleSnackbarOpen("파트너 정보가 수정되었습니다.");
    refetchDetail();
  };

  const { mutate: updatePartner } =
    ADMIN_PARTNER_MANAGEMENT_QUERY.useUpdatePartner(Number(id), handleSuccess);

  const defaultTransportModeList = (() => {
    return transportModes.map((freight) => ({
      label: freight,
      value: freight,
    }));
  })();

  const handlePartnerUpdate = () => {
    if (partnerInfo.address && partnerInfo.address.length > 200) {
      handleSnackbarOpen("주소는 200자 이하로 입력해주세요.", "warning");
      return;
    }

    if (partnerInfo.isFinancial && !isFinanceTeamUser) {
      handleSnackbarOpen(
        "금전거래 예정이 없었던 파트너와 금전거래를 시작하려면 경영지원실에 문의하여 거래처를 맵핑해주세요.",
        "warning"
      );
      return;
    }

    if (partnerInfo.shipmentTypes?.length === 0) {
      handleSnackbarOpen("수입/수출을 선택해주세요.", "warning");
      return;
    }

    updatePartner(partnerInfo);
  };

  const addressLength = (() => {
    if (partnerInfo["address"] === "") return 0;

    if (partnerInfo["address"]) {
      return partnerInfo.address.length;
    }

    return address?.length ?? 0;
  })();

  const handleShipmentTypeUpdate =
    (type: string) =>
    ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
      const shipmentTypesSet = new Set(partnerInfo.shipmentTypes);
      checked ? shipmentTypesSet.add(type) : shipmentTypesSet.delete(type);

      setPartnerInfo({ ...partnerInfo, shipmentTypes: [...shipmentTypesSet] });
    };

  return (
    <Box sx={BOX_STYLE}>
      <Box display={"flex"} flexDirection={"column"} gap={1.5}>
        <PartnerIcon />

        <Box
          alignSelf="center"
          display="flex"
          flexDirection={"column"}
          textAlign={"center"}
        >
          <Input
            sx={{ ...NO_LABEL, width: 270 }}
            inputProps={{ style: { textAlign: "center" } }}
            placeholder="파트너명"
            defaultValue={name}
            onChange={(e) =>
              setPartnerInfo({ ...partnerInfo, name: e.target.value })
            }
          />

          <Typography sx={{ mt: 1, color: "#858585" }} variant="caption">
            ID: {data.id}
          </Typography>
        </Box>

        <Box mt={2}>
          <InputLabel>파트너 유형</InputLabel>

          <PartnerType
            defaultValue={businessArea}
            handleTypeUpdate={(type: PartnerBusinessArea) => {
              setPartnerInfo({ ...partnerInfo, businessArea: type });
            }}
          />
        </Box>

        <Box>
          <InputLabel>담당 국가</InputLabel>

          <CountryInCharge
            defaultList={countries}
            countryList={[]}
            setCountryList={(list: SelectedCountry[]) => {
              const countryList = list.map((country) => country.value);
              setPartnerInfo({ ...partnerInfo, countries: countryList });
            }}
            hidesLabel
            sx={{ minWidth: 250 }}
          />
        </Box>

        <Box>
          <InputLabel>언어</InputLabel>

          <Select
            defaultValue={language}
            onChange={({ target: { value } }) => {
              setPartnerInfo({ ...partnerInfo, language: value });
            }}
            sx={{ ...NO_LABEL, width: "100%" }}
            size="small"
          >
            <MenuItem value={"kr"}>한국어</MenuItem>
            <MenuItem value={"en"}>영어</MenuItem>
          </Select>
        </Box>

        <Box>
          <InputLabel>운송 유형</InputLabel>

          <TransportMode
            defaultList={defaultTransportModeList}
            setTransportModeList={(list) => {
              setPartnerInfo({
                ...partnerInfo,
                transportModes: list.map(
                  ({ value }) => value as AdminBidTransportMode
                ),
              });
            }}
            hidesLabel
            sx={{ minWidth: 250 }}
          />
        </Box>

        <Box>
          <InputLabel>주소</InputLabel>

          <TextField
            size="small"
            sx={NO_LABEL}
            multiline
            fullWidth
            rows={2}
            defaultValue={address ?? ""}
            onChange={({ target: { value } }) => {
              setPartnerInfo({ ...partnerInfo, address: value });
            }}
          />

          <FormHelperText>{`${addressLength}/${200}`}</FormHelperText>
        </Box>

        <Box>
          <FormGroup row sx={{ color: "#00000099" }}>
            <FormControlLabel
              value={"importation"}
              control={
                <Checkbox
                  size="small"
                  checked={!!partnerInfo.shipmentTypes?.includes("importation")}
                  onChange={handleShipmentTypeUpdate("importation")}
                />
              }
              label="수입"
              labelPlacement="end"
            />

            <FormControlLabel
              value={"exportation"}
              control={
                <Checkbox
                  size="small"
                  checked={!!partnerInfo.shipmentTypes?.includes("exportation")}
                  onChange={handleShipmentTypeUpdate("exportation")}
                />
              }
              label="수출"
              labelPlacement="end"
            />
          </FormGroup>
        </Box>

        <Box>
          <FormControlLabel
            sx={{ m: 0, color: "#00000099" }}
            control={
              <Checkbox
                size="small"
                onChange={(e) => {
                  setPartnerInfo({
                    ...partnerInfo,
                    isFinancial: e.target.checked,
                  });
                }}
                defaultChecked={isFinancial}
              />
            }
            label="금전거래 여부"
            labelPlacement="start"
          />
        </Box>

        <Box>
          <FormControlLabel
            sx={{ m: 0, color: "#00000099" }}
            control={
              <Checkbox
                onChange={({ target: { checked } }) => {
                  setPartnerInfo({ ...partnerInfo, invoiceIssueFlag: checked });
                }}
                defaultChecked={invoiceIssueFlag}
              />
            }
            label="계산서 발행"
            labelPlacement="start"
          />
        </Box>

        <Divider />

        <Box mt={1} onClick={handlePartnerUpdate}>
          <Button variant="contained" fullWidth size="small">
            저장
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
