import { Chip } from "@mui/material";

export default function ChipGroup<T extends string>({
  list,
  isShipmentType,
}: {
  list: T[];
  isShipmentType?: boolean;
}) {
  if (!list.length) return <>-</>;

  return (
    <>
      {list.map((element: T) => {
        const label = (() => {
          if (isShipmentType) {
            return element === "importation" ? "수입" : "수출";
          }
          return element;
        })();

        return (
          <Chip key={element} size="small" label={label} sx={{ m: 0.1 }} />
        );
      })}
    </>
  );
}
