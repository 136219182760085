import { useState } from "react";
import TuneIcon from "@mui/icons-material/Tune";
import { Button, Popover } from "@mui/material";

import FilterBody from "./FilterBody";

export default function Filters() {
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(e.currentTarget);
  };

  const handlePopoverClose = () => setAnchor(null);

  return (
    <>
      <Button variant="outlined" endIcon={<TuneIcon />} onClick={handleClick}>
        필터
      </Button>

      <Popover
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <FilterBody />
      </Popover>
    </>
  );
}
