import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { AxiosResponse } from "axios";

import { RequestInvoiceData } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminFile";
import { ResponseFailureInfo } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  InvoiceData,
  TradingStatementElem,
} from "@sellernote/_shared/src/types/forwarding/tradingStatement";
import {
  ExchangeRate,
  InvoiceType,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import {
  calculateRTonForTradingStatement,
  checkIsIssuedInvoices,
  extractUsedExchangeRateListFromTradingStatement,
} from "@sellernote/_shared/src/utils/forwarding/tradingStatement";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import CustomsInvoiceButton from "./components/CustomsInvoiceButton";
import DeleteButton from "./components/DeleteButton";
import RefundInvoiceButton from "./components/RefundInvoiceButton";
import SaveAndDownloadButton from "./components/SaveAndDownloadButton";
import TemporarySaveButton from "./components/TemporarySaveButton";
import UserInvoiceButton from "./components/UserInvoiceButton";

//TODO: 레거시코드에서 파일 위치만 변경함 중복된 로직 수정이 필요
const RequestButton = ({
  shipmentDetailData,
  invoiceState,
  invoiceData,
  directAirRton,
  totalPrice,
  vatPrice,
  taxExemptPrice,
  exchangeRateDate,
  sessionInvoiceId,
  onSessionInvoiceChange,
}: {
  shipmentDetailData: TrelloBidDetail;
  invoiceState: ApplyBidFormData;
  invoiceData: InvoiceData | undefined;
  directAirRton: number;
  totalPrice: number;
  vatPrice: number;
  taxExemptPrice: number;
  /** 환율 기준날짜 */
  exchangeRateDate: string;
  sessionInvoiceId: string | null;
  onSessionInvoiceChange: (invoiceId: string) => void;
}) => {
  const { invoiceType } = useParams<{
    bidId: string;
    invoiceType: InvoiceType;
  }>();

  const { handleSnackbarOpen } = useSnackbar();

  const isImport = shipmentDetailData.isImport;

  const BidDetailInvoiceData = useMemo(() => {
    if (invoiceType === "refund") {
      return shipmentDetailData.refundInvoice;
    }
    if (
      invoiceType === "warehouseReceipt" &&
      shipmentDetailData.warehouseInvoice
    ) {
      const warehouseInvoiceData = shipmentDetailData.warehouseInvoice.find(
        (v) => {
          return v.id === Number(sessionInvoiceId);
        }
      );

      return warehouseInvoiceData;
    }
    if (invoiceType === "etcDeposit" && shipmentDetailData.etcDepositInvoice) {
      const etcDepositInvoiceData = shipmentDetailData.etcDepositInvoice.find(
        (v) => {
          return v.id === Number(sessionInvoiceId);
        }
      );
      return etcDepositInvoiceData;
    }

    return shipmentDetailData.bidInvoice;
  }, [
    invoiceType,
    shipmentDetailData.warehouseInvoice,
    shipmentDetailData.etcDepositInvoice,
    shipmentDetailData.bidInvoice,
    shipmentDetailData.refundInvoice,
    sessionInvoiceId,
  ]);

  const isIssuedInvoice = useMemo(() => {
    return checkIsIssuedInvoices(BidDetailInvoiceData);
  }, [BidDetailInvoiceData]);

  const isIssuedBidInvoice = useMemo(() => {
    return checkIsIssuedInvoices(shipmentDetailData.bidInvoice);
  }, [shipmentDetailData.bidInvoice]);

  const returnSaveInvoiceErrorMessage = useCallback(
    (errorCode: string) => {
      if (errorCode === "E356") {
        handleSnackbarOpen("지정된 부킹 커미션 파트너사가 아닙니다.", "error");
        return;
      }

      if (errorCode === "E071") {
        handleSnackbarOpen(
          "이미 발행 요청된 거래명세서는 저장/임시저장할 수 없습니다.",
          "error"
        );
        return;
      }

      handleSnackbarOpen("거래명세서 생성에 실패했습니다.", "error");
    },
    [handleSnackbarOpen]
  );

  const getInvoiceFeeDataForRequest = useCallback(
    (feeData: TradingStatementElem[]) => {
      if (!feeData) {
        return [];
      }

      return feeData.map((v) => {
        return {
          ...v,
          amount: (v.amount ?? "").toString(),
          itemPrice: toThousandUnitFormat(v.itemPrice),
          unitPrice: toThousandUnitFormat(v.unitPrice),
        };
      });
    },
    []
  );

  const changeExchangeRateDateToString = useCallback(
    (exchangeRateData: ExchangeRate[]) => {
      return exchangeRateData
        .filter((v) => {
          return v.currency !== "KRW";
        })
        .map((v) => {
          return v.currency === "CNH"
            ? ` CNY: ${v.rate}`
            : ` ${v.currency}: ${v.rate}`;
        })
        .toString();
    },
    []
  );

  const getInvoiceDataForRequest = useCallback(() => {
    const calculateRton = calculateRTonForTradingStatement({
      shipmentDetailData: shipmentDetailData,
      cbm: invoiceState.cbm,
      ton: invoiceState.ton,
      directAirRton,
    });

    const requestData: RequestInvoiceData = {
      bidId: shipmentDetailData.id,
      BRN: invoiceState.userBRN,
      recipient: invoiceState.recipient,
      blNumber: invoiceState.blNumber,
      voyageNumber:
        shipmentDetailData.freightType === "AIR"
          ? invoiceState.shipName
          : `${invoiceState.shipName} / ${invoiceState.voyageNo}`,
      package: invoiceState.packageValue,
      cbm:
        shipmentDetailData.freightType === "AIR"
          ? `${toThousandUnitFormat(invoiceState.ton)} kgs`
          : `${invoiceState.cbm} CBM / ${toThousandUnitFormat(
              invoiceState.ton
            )} kgs`,
      rton: calculateRton === 1 ? "1(최소값 적용)" : calculateRton.toString(),
      ...(isImport
        ? { arrivalDate: invoiceState.arrivalDate }
        : { onboardDate: invoiceState.onboardDate }),
      portOfLoading: `${invoiceState.startPort.toUpperCase()} / ${invoiceState.startCountryName.toUpperCase()}`,
      portOfDischarge: `${invoiceState.endPort.toUpperCase()} / ${invoiceState.endCountryName.toUpperCase()}`,
      incoterms: shipmentDetailData.incoterms,
      mode: shipmentDetailData.freightType,
      freightType: shipmentDetailData.freightType,
      currency: changeExchangeRateDateToString(
        extractUsedExchangeRateListFromTradingStatement(
          invoiceState,
          invoiceState.invoiceExchangeRateList
        )
      ),
      totalPrice: toThousandUnitFormat(totalPrice),
      vatPrice: toThousandUnitFormat(vatPrice),
      finalPrice: toThousandUnitFormat(totalPrice + vatPrice + taxExemptPrice),
      taxExemptPrice: toThousandUnitFormat(taxExemptPrice),
      comment: invoiceState.comment.replace(/(?:\r\n|\r|\n)/g, "<br />"),
      quotationData: {
        freightFee: getInvoiceFeeDataForRequest(invoiceState.freightFeeData),
        localFee: getInvoiceFeeDataForRequest(invoiceState.localFeeData),
        domesticFee: getInvoiceFeeDataForRequest(invoiceState.domesticFeeData),
        inlandFee: getInvoiceFeeDataForRequest(invoiceState.inlandFeeData),
        otherFee: getInvoiceFeeDataForRequest(invoiceState.otherFeeData),
        taxFee: getInvoiceFeeDataForRequest(invoiceState.taxFeeData),
      },
      containersNumberInfo:
        shipmentDetailData.locale === "KR" && shipmentDetailData.isImport
          ? undefined
          : invoiceState.containersNumberInfo.map((v) => {
              return v.containersNumber;
            }),
      exchangeRateDate,
    };

    return requestData;
  }, [
    shipmentDetailData,
    invoiceState,
    directAirRton,
    isImport,
    changeExchangeRateDateToString,
    totalPrice,
    vatPrice,
    taxExemptPrice,
    getInvoiceFeeDataForRequest,
    exchangeRateDate,
  ]);

  const handleRequestError = useCallback(
    (response: AxiosResponse<ResponseFailureInfo, any> | undefined) => {
      const failureInfo = response?.data as unknown as ResponseFailureInfo;
      returnSaveInvoiceErrorMessage(failureInfo.errorCode);
    },
    [returnSaveInvoiceErrorMessage]
  );

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        alignItems: "center",
      }}
    >
      <TemporarySaveButton
        invoiceType={invoiceType}
        getInvoiceDataForRequest={getInvoiceDataForRequest}
        handlerErrorRequest={handleRequestError}
        shipmentDetailData={shipmentDetailData}
        invoiceState={invoiceState}
        directAirRton={directAirRton}
        sessionInvoiceId={sessionInvoiceId}
        onSessionInvoiceChange={onSessionInvoiceChange}
      />

      <SaveAndDownloadButton
        invoiceType={invoiceType}
        getInvoiceDataForRequest={getInvoiceDataForRequest}
        handleRequestError={handleRequestError}
        invoiceState={invoiceState}
        shipmentDetailData={shipmentDetailData}
        directAirRton={directAirRton}
        invoiceData={invoiceData}
        sessionInvoiceId={sessionInvoiceId}
        onSessionInvoiceChange={onSessionInvoiceChange}
      />

      {invoiceType !== "refund" && shipmentDetailData.isImport && (
        <CustomsInvoiceButton
          invoiceType={invoiceType}
          getInvoiceDataForRequest={getInvoiceDataForRequest}
          handleRequestError={handleRequestError}
          shipmentDetailData={shipmentDetailData}
          invoiceState={invoiceState}
          isIssuedInvoice={isIssuedInvoice}
          isIssuedBidInvoice={isIssuedBidInvoice}
          directAirRton={directAirRton}
          sessionInvoiceId={sessionInvoiceId}
          onSessionInvoiceChange={onSessionInvoiceChange}
        />
      )}

      {invoiceType !== "refund" && (
        <UserInvoiceButton
          invoiceType={invoiceType}
          getInvoiceDataForRequest={getInvoiceDataForRequest}
          handleRequestError={handleRequestError}
          shipmentDetailData={shipmentDetailData}
          invoiceState={invoiceState}
          isIssuedInvoice={isIssuedInvoice}
          directAirRton={directAirRton}
          sessionInvoiceId={sessionInvoiceId}
          onSessionInvoiceChange={onSessionInvoiceChange}
        />
      )}

      {invoiceType === "refund" && (
        <RefundInvoiceButton
          invoiceType={invoiceType}
          getInvoiceDataForRequest={getInvoiceDataForRequest}
          handleRequestError={handleRequestError}
          shipmentDetailData={shipmentDetailData}
          invoiceState={invoiceState}
          isIssuedInvoice={isIssuedInvoice}
          directAirRton={directAirRton}
          sessionInvoiceId={sessionInvoiceId}
          onSessionInvoiceChange={onSessionInvoiceChange}
        />
      )}

      <DeleteButton invoiceData={invoiceData} />
    </Box>
  );
};

export default RequestButton;
