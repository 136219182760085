const OPTIONS_LIST = [
  {
    label: "FCL",
    value: "FCL",
  },
  {
    label: "LCL",
    value: "LCL",
  },
  {
    label: "AIR",
    value: "AIR",
  },
  {
    label: "EXPRESS",
    value: "EXPRESS",
  },
];

export default OPTIONS_LIST;
