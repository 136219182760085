import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useAtom } from "jotai";

import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";
import { FORWARDING_PARTNER_MANAGEMENT_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/partnerManagement";

export default function FilterBody() {
  const { handleSnackbarOpen } = useSnackbar();

  const [params, setParams] = useAtom(
    FORWARDING_PARTNER_MANAGEMENT_ATOMS.SEARCH_PARAMS
  );

  const handleFilterUpdate = (key: string, value: string) => {
    const formattedValue = (() => {
      if (value === "true") return true;
      if (value === "false") return false;

      return undefined;
    })();

    setParams({ ...params, [key]: formattedValue });
    handleSnackbarOpen("필터가 적용되었습니다.");
  };

  const handleShipmentTypeFilterUpdate = (value: string | undefined) => {
    setParams({ ...params, shipmentTypes: value ? [value] : undefined });
    handleSnackbarOpen("필터가 적용되었습니다.");
  };

  return (
    <Box p={3} pb={2} display={"flex"} flexDirection={"column"} gap={1}>
      <FormControl size="small">
        <FormLabel>수출입 구분</FormLabel>

        <RadioGroup row value={params.shipmentTypes}>
          <FormControlLabel
            value={undefined}
            checked={params.shipmentTypes === undefined}
            control={
              <Radio
                size="small"
                onChange={(e) => handleShipmentTypeFilterUpdate(e.target.value)}
              />
            }
            label="전체"
          />

          <FormControlLabel
            value={"importation"}
            checked={params.shipmentTypes?.includes("importation")}
            control={
              <Radio
                size="small"
                onChange={(e) => handleShipmentTypeFilterUpdate(e.target.value)}
              />
            }
            label="수입"
          />

          <FormControlLabel
            value={"exportation"}
            checked={params.shipmentTypes?.includes("exportation")}
            control={
              <Radio
                size="small"
                onChange={(e) => handleShipmentTypeFilterUpdate(e.target.value)}
              />
            }
            label="수출"
          />
        </RadioGroup>
      </FormControl>

      <Divider />

      <FormControl size="small">
        <FormLabel>활성화</FormLabel>

        <RadioGroup row value={params.isDeactivated}>
          <FormControlLabel
            value={undefined}
            checked={params.isDeactivated === undefined}
            control={
              <Radio
                size="small"
                onChange={(e) =>
                  handleFilterUpdate("isDeactivated", e.target.value)
                }
              />
            }
            label="전체"
          />

          <FormControlLabel
            value={"false"}
            control={
              <Radio
                size="small"
                onChange={(e) =>
                  handleFilterUpdate("isDeactivated", e.target.value)
                }
              />
            }
            label="활성화"
          />

          <FormControlLabel
            value={"true"}
            control={
              <Radio
                size="small"
                onChange={(e) =>
                  handleFilterUpdate("isDeactivated", e.target.value)
                }
              />
            }
            label="비활성화"
          />
        </RadioGroup>
      </FormControl>

      <Divider />

      <FormControl size="small">
        <FormLabel>거래처</FormLabel>

        <RadioGroup row value={params.hasFinancialAccounts}>
          <FormControlLabel
            value={undefined}
            checked={params.hasFinancialAccounts === undefined}
            control={
              <Radio
                size="small"
                onChange={(e) =>
                  handleFilterUpdate("hasFinancialAccounts", e.target.value)
                }
              />
            }
            label="전체"
          />

          <FormControlLabel
            value={"true"}
            control={
              <Radio
                size="small"
                onChange={(e) =>
                  handleFilterUpdate("hasFinancialAccounts", e.target.value)
                }
              />
            }
            label="존재"
          />

          <FormControlLabel
            value={"false"}
            control={
              <Radio
                size="small"
                onChange={(e) =>
                  handleFilterUpdate("hasFinancialAccounts", e.target.value)
                }
              />
            }
            label="미존재"
          />
        </RadioGroup>
      </FormControl>

      <Divider />

      <FormControl>
        <FormLabel>금전거래</FormLabel>

        <RadioGroup row value={params.isFinancial}>
          <FormControlLabel
            value={undefined}
            checked={params.isFinancial === undefined}
            control={
              <Radio
                size="small"
                onChange={(e) =>
                  handleFilterUpdate("isFinancial", e.target.value)
                }
              />
            }
            label="전체"
          />

          <FormControlLabel
            value={"true"}
            control={
              <Radio
                size="small"
                onChange={(e) =>
                  handleFilterUpdate("isFinancial", e.target.value)
                }
              />
            }
            label="가능"
          />

          <FormControlLabel
            value={"false"}
            control={
              <Radio
                size="small"
                onChange={(e) =>
                  handleFilterUpdate("isFinancial", e.target.value)
                }
              />
            }
            label="불가능"
          />
        </RadioGroup>
      </FormControl>

      <Divider />
    </Box>
  );
}
