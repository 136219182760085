const defaultPartnerForm = {
  name: "",
  businessArea: "",
  countries: [],
  language: "",
  transportModes: [],
  isFinancial: true,
  invoiceIssueFlag: true,
  managerName: "",
  managerPhone: "",
  managerPosition: "",
  managerEmail: "",
  targetAccountPayloads: [{ BRN: "", fileRefs: null }],
  address: "",
  shipmentTypes: [],
};

export { defaultPartnerForm };
