import DoneIcon from "@mui/icons-material/Done";
import { Box, Button, Chip, Divider } from "@mui/material";

import useHandleCreatePartner from "./hooks/useHandleCreatePartner";
import useHandleFileUpload from "./hooks/useHandleFileUpload";

import AccountInfo from "./AccountInfo";
import DuplicatePartnerModal from "./DuplicatePartnerModal";
import FileUploadModal from "./FileUploadModal";
import PartnerInfo from "./PartnerInfo";
import PersonInChargeInfo from "./PersonInChargeInfo";

export default function CreatePartnerModalBody({
  onModalClose,
}: {
  onModalClose: () => void;
}) {
  const {
    opensUploadModal,
    setOpensUploadModal,
    BRNFileList,
    setBRNFileList,
    bankBookCopyfileList,
    setBankBookCopyFileList,
    fileDomain,
    timeStamp,
    handleFileUploadModalOpen,
    handleFileListReset,
    validateFile,
  } = useHandleFileUpload();

  const {
    requiresAccountInfo,
    handlePartnerValidateAndCreate,
    handleCreatePartner,

    form,
    handleInfoUpdate,

    isSelected,
    setIsSelected,
    isCreate,
    setIsCreate,

    handleAutoCompleteSelect,

    duplicatePartnerList,
    showsDuplicatePartnerModal,
    setShowsDuplicatePartnerModal,
  } = useHandleCreatePartner({
    onModalClose,
    timeStamp,
    handleFileListReset,
    validateFile,
  });

  return (
    <>
      <Box display={"flex"} flexDirection={"column"}>
        <Box
          display={"flex"}
          border={1}
          borderColor={"#0000001f"}
          borderBottom={"none"}
        >
          <PartnerInfo form={form} onInfoUpdate={handleInfoUpdate} />

          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ ml: 1, mr: 1 }}
          />

          <PersonInChargeInfo form={form} onInfoUpdate={handleInfoUpdate} />
        </Box>

        {/** 금전거래 여부가 False면, 거래처 정보를 입력하지 않음 */}
        {form.isFinancial ? (
          <>
            <Divider>
              <Chip label="거래처 정보" color="info" />
            </Divider>

            <AccountInfo
              form={form}
              isSelected={isSelected}
              setIsSelected={setIsSelected}
              isCreate={isCreate}
              setIsCreate={setIsCreate}
              onAutoCompleteSelect={handleAutoCompleteSelect}
              onFileUploadModalOpen={handleFileUploadModalOpen}
              BRNFileList={BRNFileList}
              bankBookCopyfileList={bankBookCopyfileList}
              requiresAccountInfo={requiresAccountInfo}
            />
          </>
        ) : (
          <Divider />
        )}

        <Button
          size="small"
          variant="contained"
          startIcon={<DoneIcon />}
          sx={{ mt: 2 }}
          onClick={handlePartnerValidateAndCreate}
        >
          파트너 등록
        </Button>
      </Box>

      <FileUploadModal
        fileList={
          fileDomain === "financialAccountBRN"
            ? BRNFileList
            : bankBookCopyfileList
        }
        setFileList={
          fileDomain === "financialAccountBRN"
            ? setBRNFileList
            : setBankBookCopyFileList
        }
        showsUploadModal={opensUploadModal}
        onModalClose={() => setOpensUploadModal(false)}
        type={fileDomain}
        isPermission={false}
        targetId={timeStamp}
      />

      <DuplicatePartnerModal
        opensModal={showsDuplicatePartnerModal}
        onModalClose={() => setShowsDuplicatePartnerModal(false)}
        duplicatePartnerList={duplicatePartnerList}
        onCreatePartner={handleCreatePartner}
      />
    </>
  );
}
