import { useState } from "react";

import { UPDATE_PARTNER_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/partnerManagement";
import ADMIN_PARTNER_MANAGEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_PARTNER_MANAGEMENT_QUERY";
import { MappedPartnerManagerInfo } from "@sellernote/_shared/src/types/forwarding/partnerManagement";

export default function useGetPartnerDetail({ id }: { id: string }) {
  const [partnerInfo, setPartnerInfo] = useState<Partial<UPDATE_PARTNER_REQ>>(
    {}
  );

  const [managerList, setManagerList] = useState<MappedPartnerManagerInfo[]>(
    []
  );

  const { data, refetch: refetchDetail } =
    ADMIN_PARTNER_MANAGEMENT_QUERY.useGetPartnerDetail({
      id: Number(id),
      onSuccess: (res) => {
        setManagerList(res.managers);
        setPartnerInfo({ ...partnerInfo, shipmentTypes: res.shipmentTypes });
      },
    });

  return {
    data,
    refetchDetail,
    partnerInfo,
    setPartnerInfo,
    managerList,
    setManagerList,
  };
}
